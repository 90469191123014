import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import Appshell from '../components/Appshell'
import PaddedBackground from '../uikit/PaddedBackground'
import Signature from '../assets/icons/signature.svg'
import Text from '../uikit/Text'
import Button from '../uikit/Button'
import Flex from '../uikit/Flex'


const AboutAuthor = ({data}) => {
  const {frontmatter, ...pageData} = data?.markdownRemark
  return (
    <Appshell>
      <PaddedBackground
          desktopBackground={frontmatter?.desktopBackground?.publicURL}
          mobileBackground={frontmatter?.mobileBackground?.publicURL}
      >
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">{frontmatter?.title}</Text>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{__html: pageData?.html}} />
          <Flex mainAxis="end">
            <Signature />
          </Flex>
          <Flex mainAxis="center">
            <Button component={Link} isLink to="/subscription">Chcem predplatné</Button>
          </Flex>
        </Flex>
      </PaddedBackground>
    </Appshell>
  )
}

export const data = graphql`
  query AboutAuthor($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        desktopBackground {
          publicURL
        }
        mobileBackground {
          publicURL
        }
        seo {
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
        }
      }
    }
  }
`

AboutAuthor.propTypes = {
  data: PropTypes.object,
}

export default AboutAuthor
